/*!
 * Vereinscheck JavaScript Helpers v2.0
 * by Kristóf Bach
 */
var getParameterByName = function (name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

(function ($) {
    $(function () {
        
        // smooth scrolling to section
        $('[data-scroll]').on('click', function () {
            var target = $(this).data('scroll');
            var offset = $(this).data('scroll-offset');
            $('html, body').animate({
                scrollTop: $( target ).offset().top - offset
            }, 500);
        });

        $('[data-check]').on('click', function () {
            var target = $(this).data('check');
            $('input[type="checkbox"]').prop('checked', false);
            $('input[type="checkbox"]' + target).prop('checked', true);
        });

        $('[data-toggle="tooltip"]').tooltip();

        var setCookie = function (cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;
        };

        var cookieAlert = function () {
            var cookie = document.cookie.split('; ');
            if ($.inArray("cookie=1", cookie) !== -1) {
                setCookie('cookie', 1, 7);
            } else {
                $('.cookie-alert').attr('data-shown', 'true').delay(1000).slideDown();
            }
        };

        var isEmail = function (email) {
            var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(email);
        };

        $.support.transition = false;

        // jQuery Are You Sure
        /*$('form:not([action^="/teams"])').areYouSure({
            'message': are_you_sure
        });*/
        $('form.are-you-sure').areYouSure({
            message: are_you_sure
        });

        // Cookie alert
        cookieAlert();

        $('.cookie-btn').click(function () {
            $('.cookie-alert').slideUp().removeAttr('data-shown');
            setCookie('cookie', 1, 7);
        });

        // E-mail checker for mailing list subscription
        $(".newsletter-subscribe .btn").attr('disabled', 'disabled');

        $(".newsletter-subscribe .form-control").on("keyup", function () {
            if (isEmail(this.value)) {
                $(".newsletter-subscribe .btn").removeAttr('disabled');
            } else {
                $(".newsletter-subscribe .btn").attr('disabled', 'disabled');
            }
        });

        // Main page-related fixes
        $(".main-page-overlay").click(function(event){
            event.preventDefault();

            window.location = this.getAttribute('data-href');
        });

        $(".main-page-overlay-touch").click(function(event){
            var self = $(this);
            event.preventDefault();

            if (!self.hasClass('main-page-overlay-touched')) {
                self.addClass('main-page-overlay-touched');
                // Strange bug fix
                self.find(".overlay").height(0);
                self.find(".overlay").width(0);
                self.find(".overlay").css('position', 'initial');
                setTimeout(function(){
                    self.find(".overlay").css('position', 'absolute');
                    self.find(".overlay").height('100%');
                    self.find(".overlay").width('100%');
                }, 0);
                // Strange bug fix
                setTimeout(function(){
                    self.removeClass('main-page-overlay-touched');
                }, 5000);
            } else {
                window.location = this.getAttribute('data-href');
            }
        });

        // Mobile search
        var mobileSearch = false;
        $('#collapse-search-mobile').on('hide.bs.collapse', function () {
            if (mobileSearch) {
                $("#mobileSearchBar").submit();
            }
            mobileSearch = true;
        });

        $("#button-search-mobile").click(function () {
            if (startpage) {
                $("#search-box").focus();
            }
            else {
                $(".collapse-search-mobile").collapse('toggle');
            }
        });
    });

    // dynamic height sticky footer
    $(window).on('load resize', function () {
        setInterval(function() {
            $('body').css('margin-bottom', $('footer').outerHeight());
        }, 250);
    });

    // temporary label fix
    $('label[for="remember_me_navbar"]').on('click', function() {
        $('input#remember_me_navbar').click();
    });

    // Typeahead for team names
    $('.typeahead-team').typeahead({
        hint: false,
        highlight: true,
        minLength: 1
    }, {
        name: 'teams',
        source: function (query, cb, acb) {
            var sport = getParameterByName('sport');
            var url = homeURL + "vereine.json?name=" + encodeURIComponent(query);

            if (typeof sport === 'string' && sport.length > 0) {
                url = url + "&sport=" + encodeURI(sport);
            }

            $.getJSON(url, function (data) {
                acb(data.teams);
            });
        }
    });
    
    // Auto-submit forms on typeahead select
    $('.typeahead-team').on('typeahead:select', function (/*e, suggestion*/) {
        $(this).closest('form').submit();
    });

    var service = new google.maps.places.AutocompleteService();

    $('.typeahead-zip').typeahead({
        hint: true,
        highlight: true,
        minLength: 1,
        async: true
    }, {
        name: 'zips',
        source: function (query, cb, acb) {
            query = parseInt(query);
            if (isFinite(query) && query > 0 && query <= 99999) {
                service.getPlacePredictions({
                    input: query,
                    types: ['(regions)'],
                    componentRestrictions: {country: 'DE'}
                }, function (predictions) {
                    var results = [];
                    if (typeof predictions === 'object' && predictions && predictions.length > 0) {
                        var item;
                        for (var i = 0; i < predictions.length; i++) {
                            item = predictions[i].terms;
                            results.push(item[0].value);
                        }
                    }
                    acb(results);
                });
            }
        }
    });

    /*$('.typeahead-zip').keyup(function (e) {
        if ($(this).val().length === 0) {
            $('.address-city').addClass('hidden');
        }
    });

    $('.typeahead-zip').bind('typeahead:select', function (e, suggestion) {
        $('.address-city').removeClass('hidden');
    });*/

    $('.typeahead-city').keyup(function (e) {
        if ($(this).val().length === 0) {
            $('.address-street').addClass('hidden');
        } else {
            $('.address-street').removeClass('hidden');
        }
    });

    $('.typeahead-city').bind('typeahead:select', function (e, suggestion) {
        $('.address-street').removeClass('hidden');
    });

    $('.typeahead-city').typeahead({
        hint: true,
        highlight: true,
        minLength: 1,
        async: true
    }, {
        name: 'cities',
        source: function (query, cb, acb) {
            var zip = $("input[name=address_zip]").val();
            if (typeof zip !== 'undefined' && zip.length > 0) {
                query = zip + ' ' + query;
            }

            service.getPlacePredictions({
                input: query,
                types: ['(regions)'],
                componentRestrictions: { country: 'DE' }
            }, function (predictions) {
                var results = [];
                if (typeof predictions === 'object' && predictions && predictions.length > 0) {
                    var item;
                    for (var i = 0; i < predictions.length; i++) {
                        item = predictions[i].terms;
                        if (item[0].value === zip) {
                            results.push(item[1].value);
                        } else {
                            results.push(item[0].value);
                        }
                    }
                }
                acb(results);
            });
        }
    });

    $('.typeahead-street').typeahead({
        hint: true,
        highlight: true,
        minLength: 1,
        async: true
    }, {
        name: 'streets',
        source: function (query, cb, acb) {
            var zip  = $("input[name=address_zip]").val(),
                city = $("input[name=address_city]").val();
            if (typeof city !== 'undefined' && city.length > 0) {
                query = city + ', ' + query;

                if (typeof zip !== 'undefined' && zip.length > 0) {
                    query = zip + ' ' + query;
                }

                service.getPlacePredictions({
                    input: query,
                    types: ['geocode'],
                    componentRestrictions: {country: 'DE'}
                }, function (predictions) {
                    var results = [];
                    if (typeof predictions === 'object' && predictions && predictions.length > 0) {
                        var item;
                        for (var i = 0; i < predictions.length; i++) {
                            item = predictions[i].terms;
                            if (item[0].value !== zip) {
                                results.push(item[0].value);
                            }
                        }
                    }
                    acb(results);
                });
            }
        }
    });

    var ttsports = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
            url: '/sports.json',
            filter: function(data) {
                return data.sports;
            }
        }
    });
    ttsports.initialize();

    /*var $ttsports = */$('.tagsinput-sports').tagsinput({
        typeaheadjs: [
            {
                highlight: true,
                minLength: 1,
                async: true,
                autoselect: true
            }, {
                name: 'sports',
                displayKey: 'name',
                source: ttsports.ttAdapter()
                /*[
                    {
                        "id": 1,
                        "name": "Aerial Dance"
                    },
                    {
                        "id": 2,
                        "name": "Aerobic"
                    }
                ]*/
            }
        ],
        cancelConfirmKeysOnEmpty: true,
        itemValue: 'id',
        itemText: 'name',
        freeInput: false,
        confirmKeys: [13, 32, 44, 188]
    });

    /*var ttValue = "";

    $('.tagsinput-sports').tagsinput('input').bind('typeahead:render', function () {
        var newValue = $(this).typeahead('val');

        if (newValue !== ttValue) {
            $(this).typeahead('moveCursor', +1);

            ttValue = newValue;

            console.log("autoselect first");
        }
    });*/

    /*if ($ttsports && $ttsports.length > 0) {
        $ttsports = $ttsports[0];
        $ttsports.options.freeInput = true;
        $('.tagsinput-sports').on('beforeItemAdd', function (e) {
        });
        console.log("typeahead created:");
        console.log($ttsports);
    }*/

    // Geolocator for team search
    $('.geolocator').click(function (e) {
        e.preventDefault();
        var self = this;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                // set up the Geocoder object
                var geocoder = new google.maps.Geocoder();

                // turn coordinates into an object
                var location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                geocoder.geocode({location: location}, function (results, status) {
                    if (typeof results === 'object' && typeof results[0] === 'object') {
                        var locality = '';

                        $.each(results[0].address_components, function (i, address_component) {
                            if (address_component.types[0] === "locality") {
                                locality = address_component.long_name;
                            }
                        });

                        if (locality.length > 0) {
                            var form = $(self).closest('form');
                            form.find('.typeahead').val(locality);
                            form.submit();
                        } else {
                            bootbox.alert(lang.location);
                        }
                    } else {
                        bootbox.alert(lang.location);
                    }
                });
            }, function () {
                bootbox.alert(lang.location);
            });
        } else {
            bootbox.alert(lang.geoloc);
        }
    });

    // affix sidebar
    var $window = $(window),
        $affix = $('#affix-sidebar'),
        $footer = $('footer');

    if ($affix.length > 0) {
        $window.on('load resize', function () {
            $affix.width($('aside').width());
        });

        if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod' || navigator.platform === 'Linux armv6l') {
            $affix.css("position", "-webkit-sticky");
            $affix.css("top", "20px");

            $window.on('load resize', function () {
                $('aside').innerHeight($('main').innerHeight());
            });
        } else {
            $window.on('scroll touchmove', function () {
                var scrollTop = $window.scrollTop();
                if (scrollTop > $('main').offset().top) {
                    var calc = $footer.offset().top - $affix.outerHeight() - $footer.outerHeight();
                    $affix.addClass('pin-it');
                    if (scrollTop > (calc - 21)) {
                        $affix.css('top', calc - scrollTop);
                    } else {
                        $affix.css('top', '20px');
                    }
                } else {
                    $affix.removeClass('pin-it');
                }
            });
        }
    }
    $('.slick').slick();
    $('.sponsor').removeClass('hidden');
    $('.inside-link').click(function (e) {
        var $link = $(e.target).find('a').first();

        if ($link.length > 0) {
            e.preventDefault();
            $link.click();
        }
    });

    // Bottom align hack
    $window.on('resize load', function () {
        $('.pull-down').each(function () {
            var $this = $(this);
            $this.css('margin-top', $this.parent().height() - $this.height());
        });
        $('.pull-middle').each(function () {
            var $this = $(this);
            $this.css('margin-top', $this.parent().height() / 2 - $this.height() / 2);
        });
    });

    // Responsive iframe
    /*var $iframe = $('.iframe-responsive');
    if ($iframe.length > 0) {
        $window.on('resize load', function () {
            $iframe.each(function () {
                var
                    $this = $(this),
                    proportion = $this.data('proportion'),
                    w = $this.attr('width'),
                    actual_w = $this.width();

                if (!proportion) {
                    proportion = $this.attr('height') / w;
                    $this.data('proportion', proportion);
                }

                if (actual_w != w) {
                    $this.css('height', Math.round(actual_w * proportion) + 'px');
                }
            });
        });
    }*/

    // Iframe switcher

    $('.iframe-switcher-button').each(function () {
        var $this = $(this),
            $parent = $this.closest('.iframe-switcher'),
            $buttons = $parent.find('.iframe-switcher-button').not($this),
            $target = $($this.attr('data-target'));

        if ($target.length > 0) {
            $this.click(function () {
                $buttons.removeClass("active").addClass("inactive");
                $this.removeClass("inactive").addClass("active");
                $parent.children(".iframe-switcher-frame").not($target).hide();
                $target.show();
            });
        }
    });

    $('.multiselect').multiSelect({
        afterInit: function (ms) {
            var that = this,
                $selectableSearch = that.$selectableUl.closest('.has-typeahead').find('.search-input'),
                selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)';

            console.log(that.$selectableUl.closest('.has-typeahead').find('.search-input'));

            that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
                .on('keydown', function (e) {
                    if (e.which === 40){
                        that.$selectableUl.focus();
                        return false;
                    }
                });

            that.$selectableSearch = $selectableSearch;

            var selection = this.$element.val();
            if (selection === null || selection.length === 0) {
                this.$selectionUl.addClass("empty");
            }
        },
        afterSelect: function () {
            this.$selectableSearch.val("");
            this.qs1.cache();
            this.qs1.search("");

            var selection = this.$element.val();
            if (selection !== null && selection.length > 0) {
                this.$selectionUl.removeClass("empty");
            }
        },
        afterDeselect: function () {
            this.qs1.cache();

            var selection = this.$element.val();
            if (selection === null || selection.length === 0) {
                this.$selectionUl.addClass("empty");
            }
        }
    });

    // shop carousel
    $('.tabbed-content-container').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var nav = $(this).parent().find('.tabbed-nav div');
        nav.removeClass('btn-primary');
        nav.eq($(slick.$slides.get(nextSlide)).data('slick-index')).addClass('btn-primary');
    });
    $(window).on('load resize', function () {
        $('.tabbed-content-container .tabbed-image img').css('min-height', 1);
        if ( Modernizr.mq('(min-width: 992px)') && ( $('.tabbed .tabbed-content-container').height() <= $('.tabbed .nav-container').height() ) ) {
            $('.tabbed-content-container .tabbed-image img').css('min-height', ($('.tabbed-content-container').siblings('.nav-container').height() - 32)).delay( 800 );
        }
    });

    // Hack for input[type=number] accepting non-integer values
    $('input[type=number]')
        .on('input', function () {
            this.value = this.value.replace(/[^0-9]/g, '');
        })
        .attr('type', 'text');

    $('.white-textarea')
        .autogrow({
            vertical: true,
            horizontal: false
        });
})(jQuery);